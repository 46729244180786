// Events table, like http://mrolympia.com/mr-olympia
.node-main-events {
  margin-bottom: 10px;

  .main-tables-wrapper {
    @extend .cf;
  }
  .qualified-half {
    float: left;
    width: 50%;

    &.table-width-40 {
      width: 100%;

      @include mq($min: 768) {
        width: 40%;
      }
    }
    &.table-width-60 {
      width: 100%;

      @include mq($min: 768) {
        width: 60%;
      }
    }
  }
  div[class*='table-title'] {
    color: #797979;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    margin-top: 24px;
    margin-bottom: 24px;

    &.field-name-field-winners-table-title {
      margin-top: 45px;
    }
  }

  .field-collection-view-final,
  .sticky-header {
    border: 1px solid #d22d31;
    width: 90%;
    margin: auto;

    thead {
      background: #4d1311;
      color: #FFF;
      text-transform: uppercase;
      font-weight: bold;
      font-family: $main-font;
      font-size: 15px;
      border: none;
      text-shadow: 0 0 5px #000;

      th {
        padding: 9px 7px;
      }
    }
    td {
      border-top: 1px solid #DDD;
      border-left: 1px solid #DDD;
      padding: 10px 7px 6px;
      font-family: $khula-font;
      font-size: 14px;

      &:first-child {
        border-left: none;
      }
    }
    .field-name-field-placing,
    .field-points {
      text-align: center;
    }
  }

  // Remove styles for 'Past winners' table
  .field-name-field-winners-table-title {
    margin-top: 50px;
  }
  .field-name-field-past-winners-table {

    table {
      max-width: 66%;
      margin: 0 auto;
    }
    table,
    td {
      border: none;
      font-size: 16px;
    }
    .sticky-header,
    thead {
      display: none;
    }
    .field-year {
      width: 20%;
    }
  }
}
