// Homepage
#block-mro-core-mro-core-hp-video-block {
  width: 100%;
  float: left;
  margin-top: 15px;
  margin-bottom: 20px;

  @include mq($max: 599) {
    width: 100%;
    float: none;
    margin-bottom: 0;
  }

  .js-hp-video-placeholder {
    cursor: pointer;

    img {
      width: 100%;
    }
  }
  #js-hp-video {
    display: none;
    height: 0;
    position: relative;
    margin-bottom: 10px;

    iframe {
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}
#block-mro-core-mro-core-amateur-block {
  width: 25%;
  margin-top: 15px;
  float: right;

  @include mq($max: 599) {
    float: none;
    margin: 0 auto;
    max-width: 100%;
  }
}
#block-mro-core-mro-core-hp-panels-block {
  @extend .cf;
  border-top: 3px solid #000;
  border-bottom: 3px solid #000;
  font-size: 0;
  margin-bottom: 23px;

  @include mq($min: 1024) {
    margin-bottom: 30px;
  }

  @include mq($min:768, $max: 1023) {
    display: flex;
  }

  a {
    display: inline-block;

    @include mq($max: 767) {
      width: 33.3%;
    }
  }
}
.soldout-text h1 {
  font-size: 1.7em;
  @include mq($max: 767) {
    font-size: 0.77em;
  }
}

#block-mro-core-mro-core-hp-sponsor-block,
#block-mro-core-mro-core-hp-spons-block2 {
  margin-bottom: 10px;

  @include mq($min: 1024) {
    margin-bottom: 40px;
  }

  .leader-logo {
    width: auto;
    display: block;
    text-align: center;
  }

  ul {
    list-style: none;
    padding-left: 0;
    text-align: justify;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;

    .sponsors_item {
      display: inline-block;
      flex: 1 0 25%;
      margin: 13px 2%;
      text-align: center;

      @include mq($max: 767) {
        margin: 13px 0;
      }

      img {
        vertical-align: middle;
        max-width: 160px;
        max-height: 100px;
        height: auto;

        @include mq($max: 767) {
          max-width: 110px;
        }
      }
    }
  }
}

#block-mro-core-mro-core-hp-sponsor-block {
  margin-bottom: 20px;
}

#block-mro-core-mro-core-hp-spons-block2 {
  ul.sponsors_list .sponsors_item:first-child,
  ul.sponsors_list .sponsors_item:nth-child(2) {
    flex: 1 0 46%;
  }
}
