// MROL-84 Replace our subscribe form for KEAP form
.beta-base .preheader, .beta-base .header, .beta-base .sidebar, .beta-base .body, .beta-base .footer, #mainContent {
  text-align: start;
}
.beta-base .preheader, .beta-base .header, .beta-base .body, .beta-base .sidebar, .beta-base .leftSidebar, .beta-base .rightSidebar, .beta-base .footer {
  margin: 0;
  padding: 0;
  border: none;
  white-space: normal;
  line-height: normal;
}
.beta-base .title, .beta-base .subtitle, .beta-base .text, .beta-base img {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  white-space: normal;
  line-height: 1.2;
}
.beta-base .bodyContainer td.preheader{
  padding: 10px 0;
}
.beta-base .bodyContainer td.header {
  padding: 0;
  height: 30px;
}
.beta-base .bodyContainer td.body, .beta-base .bodyContainer td.footer,
.beta-base .bodyContainer td.sidebar, .beta-base .bodyContainer td.leftSidebar, .beta-base .bodyContainer td.rightSidebar {
  padding: 20px;
}
.beta-base .bodyContainer td.header p, .beta-base .bodyContainer td.preheader p, .beta-base .bodyContainer td.body p,
.beta-base .bodyContainer td.footer p, .beta-base .bodyContainer td.sidebar p,
.beta-base .bodyContainer td.leftSidebar p, .beta-base .bodyContainer td.rightSidebar p {
  margin: 0;
  color: inherit;
}
.beta-base .bodyContainer td.header div.title, .beta-base .bodyContainer td.preheader div.title, .beta-base .bodyContainer td.body div.title,
.beta-base .bodyContainer td.footer div.title, .beta-base .bodyContainer td.sidebar div.title,
.beta-base .bodyContainer td.leftSidebar div.title, .beta-base .bodyContainer td.rightSidebar div.title,
.beta-base .bodyContainer td.header div.subtitle, .beta-base .bodyContainer td.preheader div.subtitle, .beta-base .bodyContainer td.body div.subtitle,
.beta-base .bodyContainer td.footer div.subtitle, .beta-base .bodyContainer td.sidebar div.subtitle,
.beta-base .bodyContainer td.leftSidebar div.subtitle, .beta-base .bodyContainer td.rightSidebar div.subtitle,
.beta-base .bodyContainer td.header div.text, .beta-base .bodyContainer td.preheader div.text, .beta-base .bodyContainer td.body div.text, .beta-base .bodyContainer td.body div.text div,
.beta-base .bodyContainer td.footer div.text, .beta-base .bodyContainer td.sidebar div.text,
.beta-base .bodyContainer td.leftSidebar div.text, .beta-base .bodyContainer td.rightSidebar div.text {
  overflow: hidden;
  margin-bottom: 4px;
}
.beta-base .optout {
  margin-bottom: 10px;
  margin-top: 10px;
}
div.infusion-captcha {
  width: 220px;
  padding: 10px;
}
div.infusion-captcha input, div.infusion-captcha select, div.infusion-captcha textarea {
  width: 95%;
  display: inline-block;
  vertical-align: middle;
}
table.infusion-field-container {
  margin-bottom: 12px;
}
table.infusion-field-container td.infusion-field-input-container {
  padding-bottom: 12px;
}
table.infusion-field-container td + .infusion-field-label-container {
  padding-bottom: 12px !important;
}
table.infusion-field-container td.infusion-field-input-container input[type='text'],
table.infusion-field-container td.infusion-field-input-container input[type='email'],
table.infusion-field-container td.infusion-field-input-container input[type='tel'],
table.infusion-field-container td.infusion-field-input-container input[type='password'],
table.infusion-field-container td.infusion-field-input-container textarea {
  width: 100%;
  margin: 0;
}
table.infusion-field-container td.infusion-field-input-container input[type='text'],
table.infusion-field-container td.infusion-field-input-container input[type='email'],
table.infusion-field-container td.infusion-field-input-container input[type='tel'],
table.infusion-field-container td.infusion-field-input-container input[type='password'],
table.infusion-field-container td.infusion-field-input-container select {
  background-color: #fff;
  border-radius: 6px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  padding: 8px 16px;
  height: 40px;
  line-height: normal;
  outline: none;
  -webkit-appearance: none;
  -webkit-transition: border .3s ease;
  transition: border .3s ease;
  font-size: 16px;
}
table.infusion-field-container td.infusion-field-input-container input.infusion-field-required {
  border-color: red;
}
table.infusion-field-container td.infusion-field-input-container input.infusion-field-required::placeholder,
table.infusion-field-container td.infusion-field-input-container textarea.infusion-field-required::placeholder,
table.infusion-field-container td.infusion-field-label-container .span-required-field {
  color: red;
}
table.infusion-field-container td.infusion-field-input-container div.infusion-field-input-container select.infusion-field-required,
table.infusion-field-container td.infusion-field-input-container select.infusion-field-required {
  border-color: red;
  color: red !important;
}
table.infusion-field-container td.infusion-field-input-container textarea.infusion-field-required {
  border-color: red;
}
table.infusion-field-container td.infusion-field-input-container div.infusion-field-required-error {
  color: red;
  font-size: 12px;
  font-family: 'Khula', sans-serif;
  padding-left: 4px;
  padding-top: 2px;
  padding-bottom: 5px;
}
table.infusion-field-container td.infusion-field-input-container select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' id='chevron-down'%3E%3Cpath d='M6.293 9.293a1 1 0 0 1 1.414 0L12 13.586l4.293-4.293a1 1 0 1 1 1.414 1.414l-5 5a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 0-1.414z' fill-rule='evenodd'%3E%3C/path%3E%3C/svg%3E");
  background-size: 20px;
  background-position: calc(100% - 6px) center;
  background-repeat: no-repeat;
}
table.infusion-field-container td.infusion-field-input-container select {
  width: 100%;
  margin: 0;
}
table.infusion-field-container td.infusion-field-input-container select.infusion-multiselect {
  height: auto;
  background-image: none;
}
table.infusion-field-container td.infusion-field-input-container input[type='text']:focus,
table.infusion-field-container td.infusion-field-input-container input[type='email']:focus,
table.infusion-field-container td.infusion-field-input-container input[type='tel']:focus,
table.infusion-field-container td.infusion-field-input-container input[type='password']:focus {
  border-color: #bbb;
}
table.infusion-field-container td.infusion-field-label-container {
  padding-right: 5px;
  max-width: 200px;
}
.infusion-label-checkbox {
  position: relative;
  top: -3px;
}
span.infusion-checkbox {
  display: block;
  margin-left: -5px;
}
td.header .image-snippet img {
  vertical-align: bottom;
}
#webformErrors {
  color: #990000;
  font-size: 14px;
}
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}
.infusion-form {
  margin: 0;
  height: 100%;
}
.infusion-option {
  display: block;
  text-align: left;
}
@media only screen and (max-width: 675px) {
  .infusion-form .infusion-field-container,
  .infusion-form .infusion-field-container tr,
  .infusion-form .infusion-field-container td,
  .infusion-form .infusion-field-container tbody {
    display: block;
  }
  .infusion-form .infusion-field-container .pika-table tr {
    display: table-row !important;
    margin-bottom: 0 !important;
  }
  .infusion-form .infusion-field-container .pika-table td {
    display: table-cell !important;
  }
  .infusion-form .infusion-field-container .pika-table tbody {
    display: table-row-group !important;
  }
  .infusion-form table.bodyContainer {
    width: 100% !important;
  }
  .infusion-form img {
    max-width: 100%;
    height: auto;
  }
  .infusion-form .image-snippet[contentid="spotlight"] {
    width: 64px;
  }
  .infusion-form .infusion-field-input-container {
    width: 100% !important;
  }
  .infusion-form .video-container iframe {
    width: 100%;
  }
  .infusion-form .infusion-submit button {
    width: 100% !important;
  }
}

.beta-font-a h1, .beta-font-a h2, .beta-font-a h3, .beta-font-a h4, .beta-font-a h5, .beta-font-a h6 {
  font-family: 'Khula', Georgia, "Times New Roman", Times, serif;
}
.beta-font-a h1 {font-size: 24px;}
.beta-font-a h2 {font-size: 20px;}
.beta-font-a h3 {font-size: 14px;}
.beta-font-a h4 {font-size: 12px;}
.beta-font-a h5 {font-size: 10px;}
.beta-font-a h6 {font-size: 8px;}
.beta-font-a address {font-style: italic;}
.beta-font-a pre {font-family: 'Khula', Courier New, monospace;}
.beta-font-a .title, .beta-font-a .title p {
  font-weight:bold;
  font-size: 20px;
  font-family: 'Khula', Georgia, "Times New Roman", Times, serif;
}
.beta-font-a .subtitle, .beta-font-a .subtitle p {
  font-size:14px;
  font-family: 'Khula', Georgia, "Times New Roman", Times, serif;
}
.beta-font-a .text, .beta-font-a .text p {
  font-size: 16px;
  font-family: 'Khula', arial,sans-serif;
}
.beta-font-a .preheader .text, .beta-font-a .preheader .text p {
  font-size: 11px;
  font-family: 'Khula', arial,sans-serif;
}
.beta-font-a .footer .text, .beta-font-a .footer .text p {
  font-size: 10px;
  font-family: 'Khula', verdana,sans-serif;
}
.beta-font-a .footer a {
  font-size: 11px;
  font-family: 'Khula', arial,sans-serif;
}
.beta-font-a .sidebar .title, .beta-font-a .leftSidebar .title, .beta-font-a .rightSidebar .title {
  font-size: 15px;
  font-weight: bold;
  font-family: 'Khula', arial,sans-serif;
}
.beta-font-a .sidebar .subtitle, .beta-font-a .leftSidebar .subtitle, .beta-font-a .rightSidebar .subtitle {
  font-size: 12px;
  font-family: 'Khula', arial,sans-serif;
}
.infusion-field-label-container {
  color: #000000;
  font-size: 14px;
  font-family: 'Khula', arial,sans-serif;
}
.infusion-field-input-container {
  font-size: 12px;
}
.infusion-option label {
  color: #000000;
  font-size: 14px;
  font-family: 'Khula', arial,sans-serif;
}

.custom-30 .background{
  background-color:#2A2A22;
}
.custom-30 .title{
  color:#FFFFFF;
}
.custom-30 .subtitle{
  color:#666666;
}
.custom-30 .text{
  color:#cccccc;
}
.custom-30 a{
  color:#A9A9A9;
}
.custom-30 .background .preheader .text{
  color:#d4d4d4;
}
.custom-30 .background .preheader a{
  color:#d4d4d4;
}
.custom-30 .header{
  background-color:#D5D5D5;
}
.custom-30 .header .title{
  color:#000000;
}
.custom-30 .header .subtitle{
  color:#000000;
}
.custom-30 .header .text{
  color:#000000;
}
.custom-30 .header .a{
  color:#157db8;
}
.custom-30 .hero{
  background-color:#025c8d;
}
.custom-30 .hero .title{
  color:#ffffff;
}
.custom-30 .hero .subtitle{
  color:#ffffff;
}
.custom-30 .hero .text{
  color:#ffffff;
}
.custom-30 .hero .a{
  color:#157db8;
}
.custom-30 .quote{
  background-color:#013b5a;
}
.custom-30 .quote:after{
  border-color:#013b5a transparent transparent transparent;
}
.custom-30 .quote .title{
  color:#ffffff;
}
.custom-30 .quote .subtitle{
  color:#ffffff;
}
.custom-30 .quote .text{
  color:#ffffff;
}
.custom-30 .quote .a{
  color:#157db8;
}
.custom-30 .body{
  background-color:#2a2a2a;
}
.custom-30 .main{
  background-color:#ffffff;
}
.custom-30 .main .title{
  color:#000000;
}
.custom-30 .main .subtitle{
  color:#000000;
}
.custom-30 .main .text{
  color:#000000;
}
.custom-30 .main .a{
  color:#157db8;
}
.custom-30 .sidebar{
  background-color:#ffffff;
}
.custom-30 .sidebar .title{
  color:#000000;
}
.custom-30 .sidebar .subtitle{
  color:#000000;
}
.custom-30 .sidebar .text{
  color:#000000;
}
.custom-30 .sidebar .a{
  color:#157db8;
}
.custom-30 .leftSidebar{
  background-color:#ffffff;
}
.custom-30 .leftSidebar .title{
  color:#000000;
}
.custom-30 .leftSidebar .subtitle{
  color:#000000;
}
.custom-30 .rightSidebar{
  background-color:#ffffff;
}
.custom-30 .rightSidebar .title{
  color:#000000;
}
.custom-30 .rightSidebar .subtitle{
  color:#000000;
}
.custom-30 .footer{
  background-color:#D5D5D5;
}
.custom-30 .footer .text{
  color:#000000;
}
.custom-30 .footer .title{
  color:#000000;
}
.custom-30 .footer a{
  color:#A9A9A9;
}
.custom-30 .footer .subtitle{
  color:#000000;
}
.custom-30 .infusion-field-label-container{
  font-size:14px;
}
.custom-30 .infusion-field-label-container{
  font-family: 'Khula', Arial;
}
.custom-30 .infusion-field-label-container{
  color:#A9A9A9;
}
.custom-30 .infusion-field-input{
  font-size:14px;
}
.custom-30 .infusion-option label{
  font-size:14px;
}
.custom-30 .infusion-option label{
  font-family: 'Khula', Arial;
}
.custom-30 .infusion-option label{
  color:#FFFFFF;
}
.custom-30 .webFormBodyContainer{
  border-width:0px;
}
.custom-30 .webFormBodyContainer{
  border-style:Hidden;
}
.custom-30 .webFormBodyContainer{
  border-color:#000000;}

.infusion-field-label-container {
  text-align:Left;
}
.infusion-field-input-container {
  width:400px;
}
.infusion-field-label-container {
  vertical-align:Middle;
}
.bodyContainer {
  width:100%;
}
table.infusion-field-container td.infusion-field-label-container {
  display: none;
}
