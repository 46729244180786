// Footer
.footer {
  #block-mro-core-mro-core-mpartners-block {
    padding: 0 5px 23px 15px;
    background: #fff;

    @include mq($min: 1024) {
      margin: -200px 0 0px 20%;
    }

    .block__title {
      border-bottom: 1px solid #d22d31;
      color: #d22d31;
      font-family: $main-font;
      font-size: 24px;
      line-height: 1;
      text-transform: uppercase;

      @include mq($max: 1023) {
        margin-top: 0;
      }
    }
    .media-partners_list {
      padding-left: 0;
      list-style: none;
      margin-top: 30px;
      margin-bottom: 0;

      @include mq($min:768, $max: 1023) {
        display: flex;
      }
      @include mq($max: 767) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
      }

      .media-partners_item {
        display: inline-block;
        vertical-align: middle;
        margin: 0 15px 0 15px;
        max-width: 130px;

        @include mq($max: 767) {
          margin-bottom: 10px;
          max-width: 110px;
        }

        a {
          display: block;

          img {
            max-height: 60px;
          }
        }
      }
    }
  }
  #block-mro-core-mro-core-copyright-block {
    background: $black;
    border-top: 5px solid $red;
    color: #595959;
    text-align: center;
    padding: 23px 0;
    font-size: 11px;
  }
}

.footer-links__list {
  display: flex;
  justify-content: center;
  list-style: none;
  padding-left: 0;

  @include mq($max: 767) {
    flex-wrap: wrap;
    gap: 10px;
  }

  li {
    a, button {
      font-size: 12px;
      color: #b5b5b5;
      transition: color .2s ease;
      margin: 0 15px;

      &:hover {
        color: #fff;
        transition: color .2s ease;
      }
    }
  }
}
