// Tickets checkout form
.webform-client-form {
  margin-bottom: 80px;

  @include mq($max: 767) {
    margin-bottom: 50px;
  }

  .form-wrapper {
    margin-bottom: 25px;
  }

  .webform-component-fieldset {
    box-shadow: 0 0 2px rgba(#000, .1);
    border: solid 1px #bfbcbc;
    border-radius: 5px;
    padding: 10px 20px 25px;
    margin-bottom: 25px;
  }
  legend {
    background: #ffffff;
    padding: 0 10px;
  }
  .fieldset-legend {
    font-size: 18px;
    font-weight: bold;
  }
  label {
    display: block;
  }
  .form-required {
    color: $red;
  }
  .form-item {
    margin-bottom: 20px;

    input {
      width: 100%;
      border: 1px solid rgba(34,36,38,.15);
      border-radius: 3px;
    }
  }
  .webform-component--header-markup {
    margin-bottom: 15px;
    margin-top: 25px;

    p {
      font-style: italic;
      margin-bottom: 5px;
    }
  }
  label.error {
    color: $red;
    font-size: 12px;
    margin-top: 5px;
  }
  select {
    -webkit-appearance: button;
    -webkit-border-radius: 2px;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-padding-end: 20px;
    -webkit-padding-start: 2px;
    -webkit-user-select: none;
    appearance: none;
    background-image: url('../images/icon-arrow-down.svg');
    background-position: 97% center;
    background-repeat: no-repeat;
    background-size: 12px;
    border: 1px solid #ababab;
    color: #555;
    font-size: inherit;
    margin-right: 20px;
    overflow: hidden;
    padding: 8px 30px 4px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
    cursor: pointer;

    @include mq($min: 768, $max: 1024) {
      width: 250px;
    }
    @include mq($max: 767) {
      width: 100%;
    }
  }

  // Order information
  .webform-component--order-information {
    .form-item {
      display: inline-block;
      margin-right: 30px;
      vertical-align: top;
    }
  }
  .webform-component--order-information {
    .webform-component-checkboxes {
      label[for='edit-submitted-order-information-select-package'] {
        margin-bottom: 20px;
      }
      #edit-submitted-order-information-select-package {
        display: flex;
        flex-wrap: wrap;

        .form-item {
          width: 100%;
          position: relative;
          padding-left: 25px;

          @media screen and (min-width: 601px) {
            width: calc(50% - 30px);
          }

          input[type='checkbox'] {
            width: auto;
            position: absolute;
            left: 0;
            top: 3px;
          }
        }
      }
    }
  }
  .webform-component--order-information--number-of-tickets,
  .webform-component--order-information--select-package {
    @include mq($max: 767) {
      display: block;
      width: 100%;
    }
  }
  .webform-component--order-information--total-cost {
    margin-top: 15px;
    margin-bottom: 0;

    label[for='edit-submitted-order-information-total-cost'] {
      font-weight: bold;
      font-size: 16px;
    }
    label[for='edit-submitted-order-information-total-cost'].error,
    .form-required {
      display: none!important;
    }
    .field-prefix {
      font-size: 18px;
    }
    #edit-submitted-order-information-total-cost {
      border: none;
      border-bottom: solid 1px #9a9a9a;
      border-radius: 0;
      padding-bottom: 0;
      font-size: 18px;
      width: 120px;
      text-align: center;
    }
  }

  // Credit card info
  .webform-component--credit-card-information {
    .fieldset-wrapper {
      width: 100%;
      background: #e8e8e8;
      padding: 20px;
      border-radius: 5px;
    }
    .webform-component--credit-card-information--credit-card-number {
      display: inline-block;
      vertical-align: top;
      width: 47%;
      margin-right: 2%;

      @include mq($max: 767) {
        display: block;
        width: 100%;
      }
      @include mq($min: 1024) {
        width: 46%;
        margin-right: 3%;
      }

      input {
        padding: 6px 10px 1px;
        font-size: 18px;
      }
      .description {
        font-size: 12px;
        line-height: 1;
        margin-top: 7px;
      }
    }
    .webform-component--credit-card-information--security-code {
      display: inline-block;
      vertical-align: top;
      width: 47%;

      @include mq($max: 767) {
        display: block;
        width: 100%;
      }

      input {
        padding: 5px 10px 1px;
        font-size: 18px;
      }
    }
    .webform-component--credit-card-information--expiration-date-fieldset {
      padding: 10px 3% 0;

      .fieldset-wrapper {
        padding: 0;
      }
      .webform-component--credit-card-information--expiration-date-fieldset--expiration-date-month {
        display: inline-block;
        vertical-align: top;
        width: 47%;
        margin-right: 2%;

        @include mq($max: 767) {
          display: block;
          width: 100%;
        }
      }
      .webform-component--credit-card-information--expiration-date-fieldset--expiration-date-year {
        display: inline-block;
        vertical-align: top;
        width: 47%;

        @include mq($max: 767) {
          display: block;
          width: 100%;
        }
      }
    }
    #edit-submitted-credit-card-information-card-name {
      padding: 7px 10px 3px;
    }
  }

  // Billing address
  .webform-component--billing-address {
    .form-item {
      display: inline-block;
      vertical-align: top;
      width: 46%;

      &:nth-child(odd) {
        margin-right: 7%;

        @include mq($min: 768, $max: 1024) {
          margin-right: 7%;
        }
      }
      &:nth-last-child(odd) {
        margin-right: 0%;

        @include mq($min: 768, $max: 1024) {
          margin-right: 0%;
        }
      }

      @include mq($min: 768, $max: 1024) {
        width: 45%;
      }

      @include mq($max: 767) {
        display: block;
        width: 100%;
      }
      #edit-submitted-billing-address-height,
      #edit-submitted-billing-address-weight {
        width: 50%;
      }
    }
    input {
      padding: 7px 10px 2px;
    }
    .webform-component--billing-address--country {
      .description {
        margin-top: 20px;
        font-size: 14px;
        line-height: 1.4;
      }
    }
    .webform-component--billing-address--country {
      display: block;
    }
  }

  // Demographic information
  .webform-component--demographic-information {
    padding-bottom: 0;

    .form-item {
      display: inline-block;
      width: 49%;
      margin-bottom: 30px;

      @include mq($max: 767) {
        display: block;
        width: 100%;
      }
    }
    .webform-component--demographic-information--demographic-information-row {
      padding: 0;
      border: 0;
      box-shadow: none;
      margin-bottom: 0;

      legend,
      .element-invisible {
        display: none;
      }
      select {
        width: 80px;
        margin-right: 10px;
      }
      .demographic-information__block {
        display: inline-block;
        margin-right: 10px;
      }
      .form-item {
        display: inline-block;
        width: auto;
      }
      .webform-component--demographic-information--demographic-information-row--attending-time-prefix {
        margin-top: 15px;
        margin-bottom: 0;
      }
    }
    label {
      line-height: 1.3;
      margin-bottom: 6px;
    }
    .webform-component--demographic-information--special-request {
      display: block;
      width: 40%;

      @include mq($max: 767) {
        display: block;
        width: 100%;
      }

      textarea {
        width: 100%;
      }
    }
  }

  // Submit button
  .form-submit {
    display: block;
    background: #272727;
    color: #fff;
    font-size: 18px;
    padding: 9px 70px 6px;

    &:hover {
      background: $red;
    }
  }
}
.section-tickets-order {
  .messages--error {
    background: #fef5f1;
    border: 3px solid #ed541d;
    color: #8c2e0b;
    padding: 15px 20px 10px;
    margin: 25px 0 0;
    display: flex;
    align-items: center;

    .messages__icon {
      margin-right: 15px;

      svg {
        display: block;
        position: relative;
        top: -2px;

        path {
          fill: #d12d32;
        }
      }
    }
  }
}
