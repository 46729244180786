// Tickets style, e.g. http://mrolympia.com/2018/expo-tickets
.tickets-wrapper {
  @extend .cf;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.ticket {
  &-type {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    background: #EEE;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 320px;
    margin: 0 auto 25px;

    @include mq($min:768, $max: 1023) {
      width: 49%;
      margin: 1%;
    }
    @include mq($min: 1024) {
      width: 24%;
      margin: .5%;
    }

    body.section-finals-tickets & {
      @include mq($min:768) {
        width: 48%;
        margin: 1%;
      }
    }

    .fevo-button {
      display: block;
      margin: 0 auto;
      padding: 0 10px;

      img {
        display: block;
      }
    }
    span.small {
      font-size: 13px;
      color: #666;
      padding-bottom: 10px;
      display: block;
    }
  }
  &-date {
    font-weight: bold;
    font-family: $main-font;
    font-size: 24px;
    line-height: 22px;
    color: #FFF;
    padding: 10px;
  }
  &-title {
    margin-top: 0;
    padding: 10px;
    font-size: 14px;
    letter-spacing: -.5px;
    text-transform: uppercase;

    body.section-finals-tickets & {
      text-transform: none;
    }
  }
  &-price {
    font-size: 48px;
    font-weight: bold;
    font-family: $main-font;
    line-height: 1;
    color: #666;
    padding: 0 10px;
  }
  &-time {
    font-size: 13px;
    padding: 20px 0 5px;
    line-height: 1;
    clear: both;
    font-weight: 700;
  }
  &-place {
    font-size: 11px;
    text-transform: uppercase;
  }
}

