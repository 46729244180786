// Header
.header {
  position: relative;
  font-family: 'Rajdhani', sans-serif;

  .header__logo {
    //margin-left: 43px;
    margin-top: 5px;
    z-index: 10;

    @include mq($max: 980) {
      margin-top: 10px;
    }
    @include mq($max: 767) {
      position: relative;
      display: block;
      text-align: center;
      padding-top: 15px;
      margin-top: 0;
      margin-left: auto;
    }

    img {
      max-width: 365px;

      @include mq($max: 980) {
        max-width: 200px;
      }
      @include mq($max: 767) {
        max-width: 110px;
      }
    }
  }
  .header__region {
    padding-top: 20px;
    position: relative;
    border-bottom: solid 1px #fff;
    background: url('../images/header2024.jpg') no-repeat top center #343434;
    //background-color: #343434;
    background-size: cover;
  }
  .header-top__group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;

    @include mq($min: 1024) {
      margin-bottom: 10px;
    }
  }
  .header-bottom__group {
    display: flex;
    align-content: center;
    background: rgba(#fff, .8);
  }
  #block-mro-core-mro-core-location-block {
    //max-width: 400px;
    //width: 100%;
    //margin: auto;

    @include mq($max: 1024) {
      width: 100%;
      text-align: center;
      margin: 20px 0;
    }

    @include mq($max: 767) {
      text-align: center;
      min-height: 0;
      margin-bottom: 20px;
    }
  }
  h4 {
    text-align: center;
    text-transform: uppercase;
    color: #FFF;
    font-size: 42px;
    margin: 0 auto;
    line-height: 90%;
    letter-spacing: -2px;
    text-shadow: 0px 0px 50px rgba(0,0,0,1) !important;

    @include mq($max: 1065) {
      display: inline-block;
      font-size: 40px;
    }
  }

  // Countdown block
  #block-mro-core-mro-core-countdown-block {
    //@include mq($max: 767) {
    //  position: relative;
    //  right: auto;
    //  bottom: auto;
    //  text-align: center;
    //}

    @include mq($max: 1024) {
      order: 1;
      margin-top: 20px;
      margin-bottom: 10px;
    }
    @include mq($min: 1025) {
      position: relative;
      top: 8px;
    }

    .mrolympia-timer {
      width: auto;
      display: inline-block;
      margin: 0 auto;
    }
  }

  #block-mro-core-mro-core-logo-block {
    @include mq($max: 1024) {
      width: 100%;
      order: 0;
      text-align: center;
      margin-bottom: 5px;
    }
    @include mq($min: 1024) {
      margin-top: 35px;
      margin-bottom: 35px;
    }

    img {
      @include mq($max: 1024) {
        max-width: 300px;
      }
    }
  }

  // Social share buttons
  #block-mro-core-mro-core-social-block {
    .links-list {
      list-style: none;
      padding-left: 0;
      width: 140px;
      height: 100%;
	  margin: 0;
      display: flex;
      align-items: center;

      @include mq($max: 767) {
        margin: 1px 10px;
      }
      @include mq($min: 768, $max: 1023) {
        margin-left: 15px;
      }

      li {
        width: 30px;
        display: inline-block;
        margin-right: 18px;

        @include mq($max: 767) {
          width: 25px;
          margin-right: 15px;
        }
        @include mq($min: 768, $max: 1023) {
          margin: 1px 15px 1px 0;
        }

        .hidden {
          display: none;
        }

        svg {
          display: block;
          width: 27px;
          height: 27px;
        }

        a:hover {
          &.facebook-icon {
            svg {
              fill: #3c5a99;
            }
          }
          &.twitter-icon {
            svg {
              fill: #1c9ceb;
            }
          }
          &.instagram-icon {
            svg {
              fill: #d12d32;
            }
          }
        }
      }
    }
  }



  // Slider
  .region-navigation {
    @extend .cf;
  }
  #block-mro-core-mro-core-slideshow-block {
    position: relative;

    .hp-slideshow_items {
      @extend .cf;
      padding-left: 0;
      margin: 0;
      border-top: solid 1px #fff;
      list-style: none;
    }
    .hp-slideshow_pager {
      width: 63px;
      display: block;
      position: absolute;
      bottom: 5px;
      right: 20px;

      .prev,
      .next {
        display: block;
        position: absolute;
        bottom: 20px;
        z-index: 100;
        width: 27px;
        height: 27px;
        overflow: hidden;
        text-indent: -500px;
        cursor: pointer;
      }
      .prev {
        background: url(../images/arrow-left.png)no-repeat center;
      }
      .next {
        background: url(../images/arrow-right.png)no-repeat center;
        left: 36px;
      }
    }
    img {
      display: block;
    }
  }

  // Banners block
  #block-mro-core-mro-core-banners-block {
    background: #d8d8d8;
    text-align: center;
    padding: 15px 0 5px;

    a {
      display: inline-block;
      max-width: 49%;
      margin: 0 3px;

      @include mq($max: 767) {
        max-width: 100%;
      }
    }
  }
}
