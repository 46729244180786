* {
  box-sizing: border-box;
}
body {
  background: url('../images/skins/MrOlympia2019Site_skin.jpg') top center no-repeat fixed #282828;
  background-size: cover;
  font-family: $khula-font;
}
img {
  width: auto;
  max-width: 100%;
  height: auto;
}
a {
  text-decoration: none;
  color: #0074BD;
}
// Clearfix
.cf,
.clearfix {
  clear: both;
  *zoom: 1;
}

// Clearfix pseudo-elements
.cf::before,
.cf::after,
.clearfix::before,
.clearfix::after {
  content: " ";
  display: table;
}
.cf::after,
.clearfix::after {
  clear: both;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

// Hide elements on specific devices
.desktop-hidden {
  @include mq($min: 1024) {
    display: none!important;
  }
}
.tablet-hidden {
  @include mq($min: 768, $max: 1023) {
    display: none!important;
  }
}
.mobile-hidden {
  @include mq($max: 767) {
    display: none!important;
  }
}

.rtecenter {
  text-align: center;
}
.skip-link__wrapper {
  @extend .visually-hidden;
}

.layout-3col__right-content {
  .block__title {
    text-transform: uppercase;
    color: $red;
    font-family: $main-font;
    border-bottom: 1px solid $red;
  }
}

.not-front,
.section-accessibility-statement {
  h1 {
    background: url(../images/header2017.jpg) no-repeat top center $black;
    background-size: 100%;
    padding: 8px 10px;
    border-bottom: solid 1px $red;
    color: #FFF;
    text-shadow: 0 0 10px $black, 0 0 1px $black;
    text-transform: uppercase;
    font-size: 24px;
    font-family: $main-font;

    @include mq($min: 1024) {
      margin: 0px -21px;
    }
  }
}

// Remove styling from h1 on accessibility statement page
.section-accessibility-statement {
  article {
    h1 {
      background: none;
      text-shadow: none;
    }
  }
}

// Tweak accessibility btn
#audioeye_activate_btn {
  @include mq($max: 767) {
    margin-left: 0;
  }
}
#audioeye_activate_status {
  text-align: center;
}

#js-hp-video {
  margin-bottom: 20px;

  @include mq($min: 1024) {
    margin-bottom: 40px;
  }

  &:not(:empty) {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}

//Hide Amateur Olympia menu option on desktop
#block-system-main-menu > .menu .menu__item a[href='https://amateur.mrolympia.com/orlando'] {
    display: none;
}

body.page-node-3 #js-hp-video {
    padding-top: 0;
}

// Hide OT button
.ot-sdk-show-settings {
  display: none;
  font-size: 12px!important;
  border-radius: 3px;
  transition: all .3s;
  background: none!important;
  border: none!important;
  padding: 0!important;
  margin: 0 15px!important;
  color: #B5B5B5!important;
  line-height: 1.7!important;

  &:hover {
    background: transparent!important;
    color: #fff!important;
    transition: all .3s;
  }
}

#cookie-empty-placeholder {
  display: none;
}
