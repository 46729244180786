// Layout
.layout-center {
  max-width: 1040px;
  margin: 0 auto;
}
.layout-3col.layout-swap {
  @extend .cf;
  background: #fff;

  @include mq($max: 1023) {
    padding-bottom: 30px;
  }

  @include mq($min: 1024) {
    padding-bottom: 180px;
  }
}
.layout-3col__left-sidebar {
  width: 20%;
  float: left;
  border-right: solid 1px $red;
  padding-bottom: 40px;

  @include mq($max: 1023) {
    display: none;
  }
}
.layout-3col__right-content {
  width: 80%;
  float: right;
  padding: 0 20px;

  @include mq($max: 1023) {
    width: 100%;
    padding: 0 10px;
  }
}
