// weekend schedule, e.g. mrolympia.com/weekend-schedule
.weekend-schedule {
  border: 1px solid #d22d31;
  margin-top: 25px;

  th {
    background: #4d1311;
    color: #FFF;
    text-transform: uppercase;
    font-weight: bold;
    font-family: "Rajdhani",sans-serif;
    font-size: 130%;
    border: none;
    text-shadow: 0 0 5px #000;
    text-align: center;
    padding: 5px 0;
  }
  td {
    padding: 10px;
    border-top: 1px solid #DDD;
  }
}
