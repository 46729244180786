.flip-clock-wrapper {
  * {
    box-sizing: border-box;
    backface-visibility: hidden;
  }
  a {
    cursor: pointer;
    text-decoration: none;
    color: #ccc;
    &:hover {
      color: #fff;
    }
  }
  ul {
    list-style: none;
  }
  &.clearfix {
    &:before {
      content: " ";
      display: table;
    }
    &:after {
      content: " ";
      display: table;
      clear: both;
    }
    *zoom: 1;
  }
  font: normal 11px "Helvetica Neue", Helvetica, sans-serif;
  -webkit-user-select: none;
}

/* Main */

.flip-clock-meridium {
  background: none !important;
  box-shadow: 0 0 0 !important;
  font-size: 36px !important;
  a {
    color: #313333;
  }
}

.flip-clock-wrapper {
  text-align: center;
  position: relative;
  width: 100%;
  margin: 0 5px;
  padding-bottom: 10px;

  &:before {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
  }
  &:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
    clear: both;
  }
  ul {
    position: relative;
    float: left;
    margin: 1px;
    width: 24px;
    height: 37px;
    font-weight: bold;
    border-radius: 6px;
    background: #000;
    padding: 0;

    @include mq($min:768, $max: 1023) {
      width: 23px;
      height: 32px;
    }
    li {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      line-height: 40px;
      text-decoration: none !important;
      font: 28px/1.5 'Open Sans Condensed',sans-serif;

      &:first-child {
        z-index: 2;
      }
      a {
        display: block;
        height: 100%;
        perspective: 200px;
        margin: 0 !important;
        overflow: visible !important;
        cursor: default !important;

        div {
          z-index: 1;
          position: absolute;
          left: 0;
          width: 100%;
          height: 50%;
          font-size: 80px;
          overflow: hidden;
          outline: 1px solid transparent;

          .shadow {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
          }
          &.up {
            transform-origin: 50% 100%;
            top: 0;
            &:after {
              content: "";
              position: absolute;
              top: 44px;
              left: 0;
              z-index: 5;
              width: 100%;
              height: 3px;
              background-color: #000;
              background-color: rgba(0, 0, 0, 0.2);
            }
          }
          &.down {
            transform-origin: 50% 0;
            bottom: 0;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
          }
          div.inn {
            //@include linear-gradient(to bottom, #3A3A3A 50%, #444 50%);
            position: absolute;
            left: 0;
            z-index: 1;
            width: 100%;
            height: 200%;
            color: $red;
            text-shadow: rgba(225,225,225,.2) 0.5px 0.5px 0, rgba(0,0,0,.2) -1px -1px 0;
            text-align: center;
            background-color: #fff;
            border-radius: 6px;
            font-size: 24px;
          }
          &.up div.inn {
            top: 0;
          }
          &.down div.inn {
            bottom: 0;
          }
        }
      }
    }
    &.play li.flip-clock-before {
      z-index: 3;
    }
  }
  .flip {
    box-shadow: 1px 1px 1px rgba(4, 4, 4, 0.05);
  }
  ul.play li.flip-clock-active {
    animation: asd 0.5s 0.5s linear both;
    z-index: 5;
  }
}

/* Skeleton */

/* PLAY */

.flip-clock-divider {
  float: left;
  display: inline-block;
  position: relative;
  width: 15px;
  height: 45px;
  &:first-child {
    width: 0;
  }
}

.flip-clock-dot {
  display: block;
  background: #323434;
  width: 5px;
  height: 5px;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  left: 5px;
}

.flip-clock-divider {
  .flip-clock-label {
    position: absolute;
    bottom: -10px;
    top: auto;
    display: block;
    float: none;
    left: 28px;
    color: #fff;
    text-shadow: none;
    font-family: 'Rajdhani', sans-serif;
    font-size: 10px;

    @include mq($min:768, $max: 1023) {
      bottom: -5px;
    }
  }
  &.minutes .flip-clock-label {
    margin-left: -5px;
  }
  &.seconds .flip-clock-label {
    margin-left: -5px;
  }
}

.flip-clock-dot {
  &.top {
    top: 25px;
  }
  &.bottom {
    bottom: 25px;
  }
}

@-webkit-keyframes asd {
  0% {
    z-index: 2;
  }

  20% {
    z-index: 4;
  }

  100% {
    z-index: 4;
  }
}


@-moz-keyframes asd {
  0% {
    z-index: 2;
  }

  20% {
    z-index: 4;
  }

  100% {
    z-index: 4;
  }
}


@-o-keyframes asd {
  0% {
    z-index: 2;
  }

  20% {
    z-index: 4;
  }

  100% {
    z-index: 4;
  }
}


@keyframes asd {
  0% {
    z-index: 2;
  }

  20% {
    z-index: 4;
  }

  100% {
    z-index: 4;
  }
}


.flip-clock-wrapper ul {
  &.play li {
    &.flip-clock-active .down {
      z-index: 2;
      animation: turn 0.5s 0.5s linear both;
    }
    &.flip-clock-before .up {
      z-index: 2;
      animation: turn2 0.5s linear both;
    }
  }
  li.flip-clock-active {
    z-index: 3;
  }
  &.play li {
    &.flip-clock-before .up .shadow {
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, black));
      background: linear, top, rgba(0, 0, 0, 0.1) 0%, black 100%;
      background: -o-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
      background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
      background: linear, to bottom, rgba(0, 0, 0, 0.1) 0%, black 100%;
      animation: show 0.5s linear both;
    }
    &.flip-clock-active .up .shadow {
      background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, black));
      background: linear, top, rgba(0, 0, 0, 0.1) 0%, black 100%;
      background: -o-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
      background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.1) 0%, black 100%);
      background: linear, to bottom, rgba(0, 0, 0, 0.1) 0%, black 100%;
      animation: hide 0.5s 0.3s linear both;
    }
    &.flip-clock-before .down .shadow {
      background: -moz-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, black), color-stop(100%, rgba(0, 0, 0, 0.1)));
      background: linear, top, black 0%, rgba(0, 0, 0, 0.1) 100%;
      background: -o-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
      background: -ms-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
      background: linear, to bottom, black 0%, rgba(0, 0, 0, 0.1) 100%;
      animation: show 0.5s linear both;
    }
    &.flip-clock-active .down .shadow {
      background: -moz-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
      background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, black), color-stop(100%, rgba(0, 0, 0, 0.1)));
      background: linear, top, black 0%, rgba(0, 0, 0, 0.1) 100%;
      background: -o-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
      background: -ms-linear-gradient(top, black 0%, rgba(0, 0, 0, 0.1) 100%);
      background: linear, to bottom, black 0%, rgba(0, 0, 0, 0.1) 100%;
      animation: hide 0.5s 0.2s linear both;
    }
  }
}

@-webkit-keyframes turn {
  0% {
    -webkit-transform: rotateX(90deg);
  }

  100% {
    -webkit-transform: rotateX(0deg);
  }
}


@-moz-keyframes turn {
  0% {
    -moz-transform: rotateX(90deg);
  }

  100% {
    -moz-transform: rotateX(0deg);
  }
}


@-o-keyframes turn {
  0% {
    -o-transform: rotateX(90deg);
  }

  100% {
    -o-transform: rotateX(0deg);
  }
}


@keyframes turn {
  0% {
    transform: rotateX(90deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}


@-webkit-keyframes turn2 {
  0% {
    -webkit-transform: rotateX(0deg);
  }

  100% {
    -webkit-transform: rotateX(-90deg);
  }
}


@-moz-keyframes turn2 {
  0% {
    -moz-transform: rotateX(0deg);
  }

  100% {
    -moz-transform: rotateX(-90deg);
  }
}


@-o-keyframes turn2 {
  0% {
    -o-transform: rotateX(0deg);
  }

  100% {
    -o-transform: rotateX(-90deg);
  }
}


@keyframes turn2 {
  0% {
    transform: rotateX(0deg);
  }

  100% {
    transform: rotateX(-90deg);
  }
}


/* SHADOW */

/*DOWN*/

@-webkit-keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@-moz-keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@-o-keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@keyframes show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


@-webkit-keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


@-moz-keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


@-o-keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


@keyframes hide {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
