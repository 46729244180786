// Media Queries

$mq-base-font-size: 16 !default;

// Base media query type (e.g. screen, print, etc.)
$mq-type: all !default;

// Convert media query pixels to ems
@function mq-em($target, $base: $mq-base-font-size) {
  @return ($target/ $base) * 1em;
}

// Generate media query with optional parameters
@mixin mq($min: false, $max: false, $and: false, $type: $mq-type) {

  $min-width: 0;
  $max-width: 0;
  $media-query: '';

  // min: min-width breakpoint
  @if $min {
      $min-width: mq-em($min);
  }

  // max: max-width breakpoint
  @if $max {
      $max-width: mq-em($max);
  }

  // build media query
  @if $min-width != 0 { $media-query: '#{$media-query} and (min-width: #{$min-width})'; }
  @if $max-width != 0 { $media-query: '#{$media-query} and (max-width: #{$max-width})'; }
  @if $and            { $media-query: '#{$media-query} and #{$and}'; }

  // Remove unnecessary media query prefix 'all and '
  @if ($type == 'all' and $media-query != '') {
    $type: '';
    $media-query: str-slice(unquote($media-query), 6);
  }

  @media #{$type + $media-query} {
    @content;
  }

}

// mq example
//
// .div{
//    @include mq($max:800, $min: 500, $type: print, $and: (orientation: portrait)){
//      .test{
//          background: yellow;
//      }
//    }
// }
//
//
// mq example generates:
//
// @media print and (min-width: 31.25em) and (max-width: 50em) and (orientation: portrait){
//  .div{
//    .test{
//      background: yellow;
//    }
//  }
// }

@mixin retina() {
  @media (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {
    @content;
  }

}
