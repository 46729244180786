// Newsletter module wrapper
.newsletter-module {
  display: flex;
  align-items: center;
  padding: 40px 30px;
  background-color: #2a2a2a;
  position: relative;
  min-height: 260px;

  @include mq($max: 1023) {
    flex-direction: column;
    padding: 30px 20px 50px;
  }
}

// Left col with form
.newsletter-module__form {
  width: 100%;
  border-bottom: 1px solid #575757;
  padding-bottom: 20px;

  @include mq($min: 1024) {
    width: 60%;
    padding-right: 30px;
    padding-bottom: 0;
    border-right: 1px solid #575757;
    border-bottom: none;
  }

  .newsletter__header {
    margin-bottom: 20px;

    &-title {
      color: #fff;
      font-size: 26px;
      line-height: 1.2;
      text-align: center;
      margin-bottom: 10px;

      @include mq($min: 768) {
        font-size: 28px;
      }
      @include mq($min: 1024) {
        font-size: 30px;
      }
    }
    &-subtitle {
      font-size: 16px;
      line-height: 1.4;
      color: #fff;
      text-align: center;
      margin-top: 0;
      padding: 0;
      margin-bottom: 30px;

      @include mq($min: 768) {
        padding: 0 70px;
      }
    }
    .form-required {
      display: none;
    }

    // Error/success message
    label[for='edit-email'] {
      display: block;
      position: relative;
    }
    .newsletter-module {
      padding: 0;
      min-height: 20px;
      position: static;

    .newsletter-subscribe__error {
      position: absolute;

      p {
        width: 300px;
        font-size: 12px;
      }
    }
    }
  }
  .form-type-checkboxes {
    display: none;
    color: #fff;
    margin-bottom: 20px;

    #edit-type {
      margin-top: 10px;
    }
    .form-type-checkbox {
      margin-bottom: 3px;

      input {
        position: absolute;
        opacity: 0;

        &:checked + label {
          &:before {
            background: #333;
            transition: all .2s;
          }
          &:after {
            content: '';
            position: absolute;
            background: url('../images/icons-svg/icon-tick-simple.svg') center no-repeat;
            left: 4px;
            top: 4px;
            width: 12px;
            height: 12px;
          }
        }
      }
      label {
        position: relative;
        cursor: pointer;

        &:before {
          content: '';
          margin-right: 12px;
          display: inline-block;
          vertical-align: text-top;
          width: 18px;
          height: 18px;
          background: #eee;
          border: solid 1px #8c8c8c;
          position: relative;
          transition: all .2s;
        }
      }
    }
  }
  #email {
    display: block;
    width: 100%;
    background: #3e3d3e;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    border: none;
    padding: 14px 14px 8px;
    border-bottom: 2px solid transparent;
    outline: none;
    transition: all 0.2s;
  }
  #edit-submit {
    min-width: 160px;
    color: #fff;
    border: 2px solid #fff;
    display: inline-block;
    background: transparent;
    font-size: 16px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: .5px;
    text-align: center;
    text-transform: uppercase;
    padding: 15px 22px 9px;
    outline: none;
    transition: all 0.2s;
    z-index: 1;
    position: relative;

    @include mq($min: 1024) {
      font-size: 18px;
    }

    &:hover {
      color: #2A2A2A;
      background-color: #fff;
      transition: all 0.2s;
    }
  }
  .ajax-progress {
    position: absolute;
    margin-top: 5px;

    .throbber {
      display: none;
    }
    .message {
      font-size: 13px;
      color: #fff;
    }
  }
  .newsletter-disclaimer {
    color: #cccccc;
    margin-top: 25px;
    font-size: 14px;
  }

  // Success/error messages
  .newsletter-subscribe__success {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: #2a2a2a;
    color: #fff;
    text-align: center;

    h1 {
      margin-bottom: 0;
      background: transparent;
      border: none;
      font-size: 32px;
      color: inherit;
    }
    .icon-tick {
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      background: url('../images/icons-svg/icon-tick.svg') no-repeat;
      background-size: contain;
    }
  }
  .newsletter-subscribe__error {
    color: red;

    a {
      color: #fff;
      text-decoration: underline;
    }
    p {
      font-size: 14px;
      margin: 0;
    }
  }
}

// Right col with socials
.newsletter-module__socials {
  width: 100%;
  order: 1;
  flex-shrink: 0;
  padding-top: 0;
  padding-left: 20px;

  @include mq($min: 1024) {
    width: 40%;
  }

  .mrolympia-logo {
    max-width: 250px;
    margin: 0 auto;
    display: block;
  }

  h3 {
    font-size: 26px;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    color: #fff;

    @include mq($min: 768) {
      font-size: 28px;
    }
    @include mq($min: 1024) {
      font-size: 30px;
    }
  }
  .newsletter-module__socials-list {
    display: flex;
    justify-content: center;
  }
  .social-icon {
    display: block;
    width: 48px;
    height: 48px;
    text-indent: -9999px;
    background: #4A4A4A;
    margin: 0 10px;
    position: relative;
    transition: all .25s;

    &:after {
      content: '';
      display: block;
      width: 22px;
      height: 22px;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &:hover {
      opacity: .8;
      transition: all .25s;
    }

    &.fb-icon {
      &:after {
        background-image: url('../images/icons-svg/icon-facebook-w.svg');
      }
    }
    &.twitter-icon {
      &:after {
        background-image: url('../images/icons-svg/icon-twitter-w.svg');
      }
    }
    &.youtube-icon {
      &:after {
        background-image: url('../images/icons-svg/icon-youtube-w.svg');
      }
    }
    &.instagram-icon {
      &:after {
        background-image: url('../images/icons-svg/icon-instagram-w.svg');
      }
    }
  }
}

// Hide elements, when form submit successful
.form-success {
  .form-type-checkboxes,
  #email,
  #edit-submit,
  .newsletter-disclaimer {
    display: none;
  }
}

.form-error {
  label[for='edit-email'] > .newsletter-module {
    position: absolute;
    bottom: -30px;
  }
}
