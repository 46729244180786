// Main navigation
#block-system-main-menu {
  margin-bottom: 0;
  width: 100%;

  @include mq($max: 1023) {
    display: none;
  }

  & > .menu {
    width: 100%;
    margin: 0;
    padding-left: 0;
    position: relative;
    font-size: 0; // to remove space between inline-blocks

    .menu__item {
      display: inline-block;

      &:hover {
        background: #fff;

        .menu {
          z-index: 110;
        }
      }

      .menu {
        min-width: 192px;
        position: absolute;
        background: #f5f5f5;
        padding: 0;
        top: auto;
        left: 0;

        &.multicolumn {
          width: auto!important;

          li {
            display: list-item;
            width: 50%;
            float: left;
          }
        }

        li {
          color: $red;
          display: block;
          left: 0;

          a {
            font-size: 16px;
            text-transform: none;
            display: block;
          }
        }
      }
      a {
        display: inline-block;
        border: none;
        padding: 8px 10px 5px;
        font-weight: bold;
        font-size: 18px;
        line-height: 1;
        color: $red;
        text-transform: uppercase;
        font-family: $main-font;
        text-decoration: none;
      }
    }
  }
}

.mobile-navigation__wrapper {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #fff;


  @include mq($min: 1024) {
    display: none;
  }

  &:before {
    content: '';
    display: block;
    width: 0px;
    height: 0px;
    border-bottom: 34px solid #fff;
    border-left: 35px solid transparent;
    position: absolute;
    top: 0;
    left: -35px;
  }
}
.mobile-navigation {
  width: 140px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQBAMAAADt3eJSAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAHlBMVEUAAADVKzPVKyvSLTHSLTHVKzLRLjLSLTHWKTMAAAAB59PaAAAACXRSTlMAHhK/4yRC9Rk/EvUPAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAAN1wAADdcBQiibeAAAAAd0SU1FB+ILDg4yB3ndutMAAAA/SURBVAjXY2DADYQglCKDSQCIZnVmSHcFMULKGNhKgEKs7gkMYCGgAANICCwAEgILgIQgAkChMqiZHA0MeAEAAhwK1HXuljIAAAAldEVYdGRhdGU6Y3JlYXRlADIwMTgtMTEtMTRUMTQ6NTA6MDcrMDE6MDBz0jtcAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE4LTExLTE0VDE0OjUwOjA3KzAxOjAwAo+D4AAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=');
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: 110px;
  appearance: none;
  padding: 5px 10px;
  border: none;
  border-radius: 0;
  color: #d22d31;
  font-size: 18px;
  font-weight: bold;
}
