// Sidebar
#block-menu-menu-left-column-menu {
  .block__title {
    font-size: 19px;
    text-transform: uppercase;
    font-family: $main-font;
    color: $red;
    padding-left: 16px;
    margin-top: 8px;
    margin-bottom: 0;
  }
  & > .menu {
    padding-left: 0;
    list-style: none;
    border-top: $red solid 1px;
    margin-top: 10px;

    li {
      border: none;
      border-bottom: $red solid 1px;
      text-transform: uppercase;
      position: relative;

      &:hover {
        background: rgba($black, .2);

        .menu {
          visibility: visible; /* shows sub-menu */
          opacity: 1;
          z-index: 50;
          transform: translateY(0%);
          transition-delay: 0s, 0s, 0.3s; /* this removes the transition delay so the menu will be visible while the other styles transition */
        }
      }

      &.expanded {
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: 0;
          right: 15px;
          bottom: 0;
          margin: auto;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 5px 0 5px 6px;
          border-color: transparent transparent transparent #929292;
        }
      }

      a {
        display: block;
        color: $grey-90;
        font-family: $main-font;
        text-decoration: none;
        font-weight: 600;
        padding: 9px 16px;
      }

      .menu {
        min-width: 200px;
        position: absolute;
        left: 100%!important;
        bottom: calc(100% - 40px);
        background: #f5f5f5;
        padding: 0;
        box-shadow: 0px 2px 3px rgba($black, .5);
        list-style: none;
        display: none;

        &.multicolumn {
          width: 600px!important;

          li {
            display: list-item;
            width: 50%;
            float: left;
          }
        }

        li {
          color: $red;
          display: block;

          a {
            font-size: 16px;
            text-transform: none;
            display: block;
          }
        }
      }
    }
  }
}
#block-mro-core-mro-core-qualif-block {
  text-align: center;
  margin-top: 14px;
}
